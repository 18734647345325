import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import Zoom from 'react-medium-image-zoom'



//styles
import './../../styles/case_study.scss';

//images
import Cover from './../../images/case-studies/tinderbox/cover_tinderbox.png';
import Lap from './../../images/case-study-1/lap1.png';
import Phone from './../../images/case-study-1/phone.png';
import Mac from './../../images/case-study-1/mac.jpeg';


const CaseStudy_Tinderbox = () => {
  const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };
  const { scrollYProgress } = useViewportScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [1, 1.4]);
  const moveY = useTransform(scrollYProgress, [0, 1], [0, -200]);


  return (
    <div className="container-case-study">
      <div className="heading">
        <p className="project-name">tinderbox</p>
        <h2 className="project-short-description">Quickly and effortlessly create new teams in under 15 mins </h2>
      </div>
      <motion.div className="cover-container bg_tinderbox">
        <motion.img
          style={{ scale: scale }}
          className="cover-img"
          src={Cover} alt="" />

      </motion.div>

      {/* 
        <div className="cover-frame">
          <img src={Cover} alt=""/>
        </div> */}


      <div className="container-980">
        <div className="properties">
          <p className="property">Introduction</p>
          <p className="value">VoiceIQ is the intelligent phone system for SMEs that need to automate the busy work and capture insight from every call.
            <br></br><br></br>Tinderbox is a tool that use in VoiceIQ to helps you setup data configurations to run VoiceIQ on your infrastructure.</p>
        </div>
        <div className="properties">
          <p className="property">Responsibilities</p>
          <p className="value">Design UI, Create mockups and prototypes</p>
        </div>
        <div className="properties">
          <p className="property">Timeline</p>
          <p className="value">Feb - March 2019 (3 weeks)</p>
        </div>
        <div className="properties">
          <p className="property">Tools</p>
          <p className="value">Sketch, Abstract, Illustrator</p>
        </div>
      </div>
      
      <img src={Cover} alt="" className="image-full-width" />
      <img src={Phone} alt="" className="image-auto-width" />
      <Footer />
    </div>


  );
};

export default CaseStudy_Tinderbox;