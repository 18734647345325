import {React, useEffect} from "react";
import Footer from "../components/footer";
import SmoothScroll from "../components/smoothScroll";
import ProgressiveImage from "react-progressive-image";
import { useAnimation, motion } from "framer-motion";
import { useLocation } from "react-router-dom";


//images
import Me1 from './../images/about-yasintha.png';
import WhyIDesign from './../images/about/why_I_design.png';
import HowIDesignPlaceHolder from './../images/about/design-process-low-res.jpeg';
import HowIDesign from './../images/about/design-process.webp';


//styles
import './../styles/about.scss';

const Title = {
  initial: { opacity: 0, y:80 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: .9,
      ease: 'easeOut'
    }
  },

}
const body = {
  initial: { opacity: 0, y:-30 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      delay: .12,
      duration: .4,
      ease: 'easeOut',
      staggerChildren: 0.11,
    }
  },

}
const ImageGallery = {
  initial: { opacity: 1 },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
      duration: 1.4,
      ease: 'easeOut'
    }
  },

}

const Item = {
  initial: { opacity: 0, y: -50 },
  animate: {
    opacity: 1, y: 0,
    transition: {
      ease: [0.45, 0, 0.55, 1],
      duration: .7,
    },
  },
}


const About = () => {

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // window.scrollTo(0, 0);
  
  return (
    <>
      <motion.div className="wrapper"

      >
        <div className="about">
          <motion.div
            variants={Title}
            initial='initial'
            whileInView='animate'
            viewport={{ once: true, amount: 0.8 }}

            className="welcome">Yo!</motion.div>
          <motion.div
            initial='initial'
            whileInView='animate'
            viewport={{ once: true, amount: 0.8 }}

            variants={body}
            className="description">My name is Yasintha Perera. As a UI/UX designer with a background in s/w engineering, I have a multifaceted understanding of the user experience that people have with physical and digital products everyday.
            <br /><br />To me, User Experience Design means making things easier (and fun, when appropriate) for people to achieve their tasks.My favorite example of good UX is when I order food through an app and I can see in a little map when my food will arrive.<span className="text-bold">#mindblown</span></motion.div>

          <div className="img-with-figure">
            <motion.img
              initial='initial'
              whileInView='animate'
              viewport={{ once: true}}
              variants={ImageGallery} 
              src={Me1} alt="" />
            <span className="img-figure"></span>
          </div>
        </div>
      </motion.div>

      <div className="why">
        <div className="container-700">
          <h3>Why I design?</h3>
          <p className="description">A phrase that constantly pops into my head is  "shouldn't this be easier to do?"</p>
        </div>
        <div className="image-980">
          <img src={WhyIDesign} alt="Why I design" />
        </div>
        <div className="container-700">
          <p className="description">I grew tired of wondering and equipped myself with the knowledge to create digital products and experiences that are useful and delightful. My dream is to one day hear a random person say they love a product/feature that I took part in designing.</p>
        </div>

      </div>

      <div className="how">
        <div className="container-700">
          <h3>How I design?</h3>
          <p className="description">I strongly believe that at least 80% of the problem is solved when you truly understand the problem.</p>
          <span className="quote">"If I had an hour to solve a problem, I'd spend 55 minutes thinking about the problem and five minutes thinking about solutions." -Albert Einstein</span>
        </div>
        <div className="image-980">
          <img src={HowIDesign} alt="Design process" />
        </div>
      </div>

      <motion.div
        variants={ImageGallery}
        initial='initial'
        whileInView='animate'
        viewport={{ once: true, }}

        className="what">
        <div className="container-700">
          <h3>What makes my day brighter?</h3>
          <p className="description">In my spare time, I trying motion design and help other designers improve their skills by sharing UI & UX related insights. Besides that, I enjoy travelling, skating, collecting sneakers, good music, and taking photos.</p>
        </div>
        <div className="image-gallery">
          <motion.img variants={Item} viewport={{ once: true }} src={require('./../images/about/compressed/1.webp')} alt="Sunrise at Kithulgala, Sri Lanka" />
          <motion.img variants={Item} viewport={{ once: true }} className="move-down" src={require('./../images/about/compressed/2.webp')} alt="Marina Bay, Singapore" />
          <motion.img variants={Item} viewport={{ once: true }} src={require('./../images/about/compressed/6.webp')} alt="Skating Yasintha" />
          <motion.img variants={Item} viewport={{ once: true }} className="move-down" src={require('./../images/about/compressed/3.webp')} alt="Dron + Sneakers" />

          <motion.img variants={Item} viewport={{ once: true }} className="move-up" src={require('./../images/about/compressed/4.webp')} alt="Waterfall in Sri Lanka" />
          <motion.img variants={Item} viewport={{ once: true }} className="" src={require('./../images/about/compressed/5.webp')} alt="Sentosa, Singapore" />
          <motion.img variants={Item} viewport={{ once: true }} className="move-up" src={require('./../images/about/compressed/7.webp')} alt="Lightshow Singapore 2022" />
          <motion.img variants={Item} viewport={{ once: true }} className="" src={require('./../images/about/compressed/8.webp')} alt="Ella, Sri Lanka" />
        </div>
      </motion.div>

      <div className="mini-cv">
        <div className="container-700">
          <h3>Experience</h3>
          <div className="experince">

            <div  className="company">
              <div className="com-name">Singapore Airlines - UX Strategist</div>
              <div className="responsible">Responsible for the UI & UX design, decision-making and consultancy in maintaining the Web Design System.</div>
              <div className="date">2019 oct - Present</div>
            </div>

            <div  className="divider-nm"></div>

            <div  className="company">
              <div className="com-name">VoiceIQ - UI Designer</div>
              <div className="responsible">Together with UX researchers, data analysts, developers, and product owners I made developer tools.</div>
              <div className="date">2019 Jan - 2019 Jul</div>
            </div>
            <div  className="divider-nm"></div>

            <div  className="company">
              <div className="com-name">LB Finance - UI/UX Enginner</div>
              <div className="responsible">I helped to design and develop digital products for corporates needs.</div>
              <div className="date">2018 may - 2019 jan</div>
            </div>

            <div  className="divider-nm"></div>

            <div  className="company">
              <div className="com-name">Microsoft - DX Intern</div>
              <div className="responsible">As an intern, I helped to design and develop PoCs using current and emerging Microsoft technologies.As well as provide guide university students to participate and win Microsoft Imagine Cup 2017 </div>
              <div className="date">2018 may - 2019 jan</div>
            </div>

          </div>
        </div>
      </div>
      <Footer /></>

  );
};

export default About;