
import React from "react";
import * as LottiePlayer from "@lottiefiles/lottie-player";
import './../styles/works-page.scss';
import Footer from "../components/footer";



const Works = () => {
    return (
        <>
{/* 
            <div className="as-cover">
                <img src={require('./../images/banner 2k @1x.png')} alt="" />
            </div> */}
            {/* <div className="as-background">

            </div> */}
            <div className="wrapperx">
                <h1>Some works will appear here</h1>

                <div className="lottie">
                    <lottie-player
                        autoplay

                        loop
                        mode="normal"
                        src="https://assets9.lottiefiles.com/packages/lf20_iv4dsx3q.json"
                    >
                    </lottie-player>

                </div>
            </div>
            <Footer />
        </>
    )
};

export default Works;