import { React } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import ProgressiveImage from "react-progressive-image";

import HeroImageMobile from './../images/hero-img-mobile.webp';
import HeroImageMobilePlaceHolder from './../images/hero-img-mobile-placeholder.webp';
import HeroImageDesktop from './../images/hero-img.webp';
import HeroImageDesktopPlaceHolder from './../images/hero-img-placeholder.webp';
import { HashLink as LinkA } from 'react-router-hash-link';
import NortableWorks from "../components/nortable-works";
import ScrollToTop from "./scrollToTop";

import './../styles/home.scss';
import './../styles/_mixing.scss';
import Footer from "../components/footer";
import SmoothScroll from "../components/smoothScroll";


const Home = () => {


    return (
        <>
            <div className="container">

                {/* <ProgressiveImage
                    src={Image}
                    placeholder={ImagePlaceHolder}>
                    {(src) => (
                        <motion.img className="thumbail"
                            src={src}
                            alt='Yasmeen Tariq'

                        />
                    )}
                </ProgressiveImage> */}
                <motion.div
                    className="hero">
                    <motion.h1  // initial={{ scale:1 }}
                        animate={{ scale: [2, 1], }}
                        transition={{ duration: 1.6, ease: "easeInOut" }}
                        className="title">Humanize the design</motion.h1>

                    <motion.p
                        animate={{
                            y: [90, 0],
                            opacity: [0, 1]
                        }}
                        transition={{ delay: 1.3 }}
                        className="body">A UX/UI Designer based in <span className="text-line-through">Sri Lanka</span> Singapore. I'm passionate about working on complex problems and crafting joyful environments with holistic thinking.
                    </motion.p>

                    <motion.div
                        animate={{

                            y: [100, 15],
                            opacity: [0, 1]
                        }}
                        transition={{ delay: 1.5 }}>
                        <LinkA className="cta" to="#nortable-works">See my work</LinkA>
                    </motion.div>
                    <ProgressiveImage
                        src={HeroImageDesktop}
                        placeholder={HeroImageDesktopPlaceHolder}
                    >
                        {(src) => (
                            <motion.img className="hero-img-desktop"
                                src={src}
                                alt='Yasintha Perera | UI/UX Designer' />
                        )}
                    </ProgressiveImage>
                    <ProgressiveImage
                        src={HeroImageMobile}
                        placeholder={HeroImageMobilePlaceHolder}>
                        {(src) => (
                            <motion.img className="hero-img-mobile"
                                src={src}
                                alt='Yasintha Perera | UI/UX Designer' />
                        )}
                    </ProgressiveImage>
                </motion.div>
            </div>
           
            <section className="design-intro">
                <h3 className="title">Designing Interfaces as a <br></br>Language of Communication</h3>
                <div className="body">As interfaces play an ever-increasing role in our daily lives,
                    it is crucial to ensure they are effortless, ethical, and accessible to use. Good design
                    goes beyond aesthetics and serves as a business catalyst, an expression of brand identity,
                    a means of user empowerment, and a form of communication.</div>

            </section>
            <section className="about-me">
                <div className="intro">Currently, I’m working  at Singapore Airlines as a UX Strategist</div>
              
                {/* <Link className="cta" to="/about">More about me</Link> */}
                <a className="cta" href="/about">More about me</a>
            </section>
            <Footer />
        </>
    );
};
export default Home;
