import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

//images
import LinkedInIcon from './../images/social-media/linkedin-fill 1.svg';
import TwitterIcon from './../images/social-media/twitter-fill 1.svg';
import InstagramIcon from './../images/social-media/instagram-line 1.svg';
import BehanceIcon from './../images/social-media/behance-fill 1.svg';
import DribbleeIcon from './../images/social-media/dribbble-line 1.svg';

//styles
import './../styles/contact.scss';

const ContactPage = () => {

    // open in new tab
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    const GetInTouch = {
        initial: { opacity: 0 },
        animate: {
            opacity: 1,
            transition: {
                staggerChildren: .35,
            },
        },

    }
    const Header = {
        initial: { opacity: 0 },
        animate: {
            opacity: 1,
            transition: {
              
                duration: 1.6,
                ease: 'easeOut'
            }
        },

    }
    const Item = {
        initial: { opacity: 0, y: -50 },
        animate: {
            opacity: 1, y: 0,
            transition: {
                ease: [0.45, 0, 0.55, 1],
                duration: .9,
            },
        },


    }

    return (
        <div className="wrapper">
            <motion.div
                variants={GetInTouch}
                initial='initial'
                animate='animate'
                className="container-contact">
                <motion.h1
                    variants={Header}
                    className="hear">Love to hear from you!</motion.h1>
                <motion.div

                    className="get-in-touch">
                    <motion.p variants={Item}>get in touch</motion.p>
                    <motion.h3 variants={Item}>bestofyasi@gmail.com</motion.h3>
                </motion.div>
                <motion.div variants={Item} className="social-media">
                    <p>you can find me here</p>
                    <div className="icons">
                        <img src={LinkedInIcon} alt="LinkedIn Yasintha" onClick={() => openInNewTab('https://www.linkedin.com/in/yasintha/')} />
                        <img src={TwitterIcon} alt="Twitter Yasintha" onClick={() => openInNewTab('https://twitter.com/whyPerera')} />
                        <img src={InstagramIcon} alt="Instagram Yasintha" onClick={() => openInNewTab('https://instagram.com/bestofyasi')} />
                        <img src={BehanceIcon} alt="Behance Yasintha" onClick={() => openInNewTab('https://www.behance.net/yasintha/')} />
                        <img src={DribbleeIcon} alt="Dribbble Yasintha" onClick={() => openInNewTab('https://www.dribbble.com/yasintha/')} />
                    </div>
                </motion.div>
            </motion.div>
        </div>
    );
};

export default ContactPage;
