import React from "react";
import { Link } from "react-router-dom";
import YasinthaLogo from './../images/logo-yasintha.svg';

//styles
import './../styles/header.scss';
import MenuToggle from "./menu_toggle";

const Header = () => {

  return (
    <nav>
      <div className="logo">
        <Link to="/">
          <img src={YasinthaLogo} />
        </Link>
      </div>

      <div className="nav-items">
        <ul>
          <li>
            <Link to="/works">Works</Link>
          </li>
          <li>
            <Link to="/animations">Explorations</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          {/* <li>
      <Link to={`/model/yasmeen-tariq`}>yasmeen</Link>
    </li>
    <li>
      <Link to={`/model/yasintha`}>Yasi</Link>
    </li> */}
        </ul>

      </div>
      <div className="menu">
        <MenuToggle/>

      </div>
    </nav>

  );
};

export default Header;
