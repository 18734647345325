import './App.scss';
import { BrowserRouter as Router, Routes, Switch, Route, Link, Outlet } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Header from './components/header';
import Home from './pages/home';
import About from './pages/about';
import MenuToggle from './components/menu_toggle';
import Case1 from './pages/case_study_1';
import CaseStudy_Tinderbox from './pages/case studies/tinderbox';
import CaseStudy_KFGamification from './pages/case studies/kf-gamification';
import AnimationTemplate from './pages/animations';


import { Example } from "./menu/Example";
import ScrollToTop from "./pages/scrollToTop";


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import ContactPage from './pages/contact';
import Works from './pages/works';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB9_s2LOCXzNBxV5nVQS5ZQsxHvu49NH7k",
  authDomain: "yasintha-s-portfolio.firebaseapp.com",
  projectId: "yasintha-s-portfolio",
  storageBucket: "yasintha-s-portfolio.appspot.com",
  messagingSenderId: "633091388818",
  appId: "1:633091388818:web:80ff15bc80e3825f5c7d4b",
  measurementId: "G-2Q2WJCZRWY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {

  return (
    <Router>
      <Header />
      <ScrollToTop />
      <div>
        <Routes >
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact"  element={<ContactPage />} />
          <Route path="/works" element={<Works />} />
 
          <Route exact path="/case-study-tinderbox" element={<CaseStudy_Tinderbox />} />
          {/* <Route exact path="/case-study-kf-gamification" element={<CaseStudy_KFGamification />} /> */}

          {/* <Route path="/animations" element={<AnimationTemplate />} /> */}
          {/* <Route path="/explorations" element={<Example />} /> */}
          <Route path='/model/:id' element={<Model />} />
        </Routes>
      </div>
    </Router>
  );
}



export default App;


function Model() {
  return (
    <div>
      <h1>Yasmeeen</h1>
      <Outlet />
    </div>
  );
}
function Model2() {
  return (
    <div>
      <h1>Yasin</h1>
    </div>
  );
}